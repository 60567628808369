<template>
  <div class="sidebar">
    <BaseIcon class="logo" name="logo" size="40" color="unset" />

    <ul class="menu">
      <li
        class="menu__item"
        v-for="item in sidebarItems"
        :key="item.label"
        @mouseenter="showMenu(item)"
        @mouseleave="hideMenu(item)"
      >
        <q-menu
          v-if="item.hasPopup"
          anchor="top right"
          self="top left"
          :offset="[16, 0]"
          class="menu-popup"
          ref="tooltipMenuRef"
          @mouseover="overMenu = true"
          @mouseleave="hideMenu(item)"
        >
          <q-item>
            <q-item-section style="color: var(--slate-400); cursor: default"
              >Подбор:
            </q-item-section>
          </q-item>
          <q-separator style="background: #3d3952" />
          <q-item clickable v-for="route in item.popUp">
            <q-item-section>
              <nuxt-link
                v-if="route.route"
                style="text-decoration: none; color: inherit"
                :to="route.route"
              >
                {{ route.label }}
              </nuxt-link>
              <!-- <nuxt-link style="text-decoration: none; color: inherit" :to="route.route">
                {{ route.label }}
              </nuxt-link> это юзаем для прода!!!! -->
            </q-item-section>
          </q-item>
        </q-menu>

        <nuxt-link
          class="menu__link"
          :to="item.route"
          @mouseenter="showMenu(item)"
          @mouseleave="hideMenu(item)"
        >
          <q-tooltip
            :style="{ display: item.hasPopup ? 'none' : 'blok' }"
            v-if="!item.hasPopup"
            anchor="center right"
            self="center left"
            class="menu__link-tooltip"
            :offset="[20, 10]"
          >
            {{ item.label }}</q-tooltip
          >
          <nuxt-icon class="menu__icon" :name="item.icon"> </nuxt-icon>
        </nuxt-link>
      </li>
      <li class="menu__item" style="margin-top: auto">
        <a
          class="menu__link"
          :href="`${currenDomen}/docs/docs.html`"
          target="_blank"
          rel="noopener noreferrer"
        >
          <q-tooltip
            anchor="center right"
            self="center left"
            class="menu__link-tooltip"
            :offset="[20, 10]"
          >
            Документы</q-tooltip
          >
          <nuxt-icon class="menu__icon" name="book"> </nuxt-icon>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const currenDomen = ref(window.location.origin)

const sidebarItems = ref([
  {
    label: 'Главная',
    route: '/',
    icon: 'main',
    id: 0,
    hasPopup: false,
    hasToolTip: true,
  },
  {
    label: 'Сделки',
    route: '/deals',
    icon: 'briefcase',
    id: 0,
    hasPopup: false,
    hasToolTip: true,
  },
  // {
  //   label: 'Клиенты',
  //   route: '', //тестовое!!!!!
  //   icon: 'users',
  //   id: 0,
  //   hasPopup: false,
  //   hasToolTip: true,
  // },
  {
    label: 'Подбор',
    route: '',
    icon: 'build',
    id: 0,
    hasPopup: true,
    popUp: [
      { label: 'Объектов', route: '/search-objects' },
      { label: 'Покупателей', route: '/search-purchaser' },
      { label: 'Новостроек', route: '/new-buildings' },
    ],
    hasToolTip: false,
  },
  {
    label: 'Комьюнити',
    route: '/community',
    icon: 'community',
    id: 0,
    hasPopup: false,
    hasToolTip: true,
  },
  // {
  //   label: 'Чат',
  //   route: '', //тестовое!!!!!
  //   icon: 'chat',
  //   id: 0,
  //   hasPopup: false,
  //   hasToolTip: true,
  // },
  {
    label: 'Обучение',
    route: '/training',
    icon: 'lamp',
    id: 0,
    hasPopup: false,
    hasToolTip: true,
  },
  {
    label: 'Инвестиции',
    route: 'investments',
    icon: 'coin',
    id: 0,
    hasPopup: false,
    hasToolTip: true,
  },
])

const tooltipMenuRef = ref(null)
const overMenu = ref(false)

const showMenu = (item) => {
  if (item.hasPopup) {
    tooltipMenuRef.value[0]?.show()
  }
}

const hideMenu = (item) => {
  if (item.hasPopup) {
    overMenu.value = false
    setTimeout(() => {
      if (!overMenu.value) {
        tooltipMenuRef.value[0]?.hide()
      }
    }, 100)
  }
}
</script>

<style lang="scss">
.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 8px;
  height: 100vh;
  background: var(--slate-800);
  width: 65px;
  &__logo {
    color: var(--white, #fff);
    svg {
      width: 40px;
      height: 40px;
    }
  }
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  margin: 0;
  padding: 0;
  list-style-type: none;

  &__link {
    display: block;
    padding: 8px;
    border-radius: 8px;
    background: var(--slate-800);
    color: var(--slate-400);
    list-style: none;
    margin: 0;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    &-tooltip {
      border-radius: 4px;
      background: var(--slate-800);
      color: var(--white, #fff);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
    &:hover {
      background: var(--slate-700);
    }
    &.active {
      color: var(--white, #fff);
      background: var(--violet-400);
    }
  }
  &__icon svg {
    width: 24px;
    height: 24px;
  }
}
.menu-popup {
  background: var(--slate-800);
  border-radius: 8px;
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.menu-popup .q-item {
  height: 36px !important;
  min-height: 36px !important;
}
.logo {
  .nuxt-icon.nuxt-icon--fill * {
    fill: revert-layer !important;
  }
}
</style>
